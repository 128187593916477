<template>
    <div class="golas">
        <el-dialog center :visible.sync="dialogVisible" width="690px">
            <div slot="title">
                <h4>{{ $t(title) }}</h4>
            </div>
            <el-form label-position="top" ref="form" size="small" :model="form" label-width="100px">
                <el-form-item prop="exam_date" :label="`${$t('考试日期')}:`"
                    :rules="[{ required: true, message: '请设置考试日期', trigger: 'blur' },]">
                    <el-date-picker v-if="!$store.state.reload" :picker-options="options" style="width:200px;" value-format="yyyy-MM-dd"
                        v-model="form.exam_date" type="date" :placeholder="$t('选择日期')"></el-date-picker>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item prop="exam_data" :label="`${$t('考试目标')}:`"
                    :rules="[{ required: true, message: $t('请设置考试目标'), trigger: 'blur' },]">
                    
                    <div class="flex flex flex-v-center margin-t">
                        <div class="margin-x">
                            <div>{{$t('口语')}}</div>
                            <el-input ref="input0" type="number" style="width:78px" v-model="form.exam_data[0]"
                                :placdeholder="$t('口语')" controls-position="right" :min="10" :step="5" :max="90"></el-input>
                        </div>

                        <div class="margin-x">
                            <div>{{$t('写作')}}</div>
                            <el-input ref="input1" type="number" style="width:78.5px" v-model="form.exam_data[1]"
                                :placdeholder="$t('口语')" controls-position="right" :min="10" :step="5" :max="90"></el-input>
                        </div>
                        <div class="margin-x">
                            <div>{{$t('阅读')}}</div>
                            <el-input ref="input2" type="number" style="width:78.5px" v-model="form.exam_data[2]"
                                :placdeholder="$t('口语')" controls-position="right" :min="10" :step="5" :max="90"></el-input>
                        </div>
                        <div class="margin-x">
                            <div>{{$t('听力')}}</div>
                            <el-input ref="input3" type="number" style="width:78.5px" v-model="form.exam_data[3]"
                                :placdeholder="$t('口语')" controls-position="right" :min="10" :step="5" :max="90"></el-input>
                        </div>

                        <div class="margin-x">
                            <div>{{$t('单项不低于')}}</div>
                            <el-input ref="input10" type="number" style="width:78.5px" v-model="form.exam_data[10]"
                                :placdeholder="$t('口语')" controls-position="right" :min="10" :step="5" :max="90"></el-input>
                        </div>

                        <div class="margin-x">
                            <div class="bold">{{$t('总分')}}</div>
                            <el-input ref="input4" type="number" style="width:78.5px" v-model="form.exam_data[4]"
                                :placdeholder="$t('口语')" controls-position="right" :min="10" :step="5" :max="90"></el-input>
                        </div>
                    </div>

                </el-form-item>


                <el-form-item v-show="false" prop="exam_data" :label="`${$t('历史成绩')}:`">
                    <div class="flex flex flex-v-center">
                        <div class="margin-x">
                            <div>{{$t('口语')}}</div>
                            <el-input ref="input5" type="number" style="width:100px" v-model="form.exam_data[5]"
                                :placdeholder="$t('口语')" controls-position="left" :min="10" :step="5" :max="90"></el-input>
                        </div>

                        <div class="margin-x">
                            <div>{{$t('写作')}}</div>
                            <el-input ref="input6" type="number" style="width:100px" v-model="form.exam_data[6]"
                                :placdeholder="$t('口语')" controls-position="right" :min="10" :step="5" :max="90"></el-input>
                        </div>
                        <div class="margin-x">
                            <div>{{$t('阅读')}}</div>
                            <el-input ref="input7" type="number" style="width:100px" v-model="form.exam_data[7]"
                                :placdeholder="$t('口语')" controls-position="right" :min="10" :step="5" :max="90"></el-input>
                        </div>
                        <div class="margin-x">
                            <div>{{$t('听力')}}</div>
                            <el-input ref="input8" type="number" style="width:100px" v-model="form.exam_data[8]"
                                :placdeholder="$t('口语')" controls-position="right" :min="10" :step="5" :max="90"></el-input>
                        </div>

                        <div class="margin-x">
                            <div class="bold">{{$t('总分')}}</div>
                            <el-input ref="input9" type="number" style="width:100px" v-model="form.exam_data[9]"
                                :placdeholder="$t('口语')" controls-position="right" :min="10" :step="5" :max="90"></el-input>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="primary" round style="width:200px" @click="submit">{{$t('提交')}}</el-button>
            </div>

        </el-dialog>
    </div>
</template>
  
<script>
export default {
    name: "goalDialog",
    data() {
        return {
            options: {
                disabledDate: (time) => {
                    return time.getTime() < Date.now();
                }
            },
            dialogVisible: false,
            title: "设定目标",
            form: { "exam_data": [60, 60, 60, 60, 60, 60, 60, 60, 60, 60,60], "exam_date": "" },
        };
    },
    methods: {
        show() {

            if (this.$store.state.user.exam_data) {
                this.form.exam_data = JSON.parse(JSON.stringify(this.$store.state.user.exam_data));
            }


            if (this.$store.state.user.exam_date) {
                this.form.exam_date = this.$store.state.user.exam_date;
            }
            this.dialogVisible = true;
        },

        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let flag = false;
                    this.form.exam_data.forEach((score, index) => {
                        if ((index<5 || index==10) &&(score < 10 || score > 90)) {
                            this.$refs["input" + index].focus();
                            flag = true;
                        }
                    })

                    if (flag) {
                        this.$message({
                            message: '分数在10~90!',
                            type: 'primary'
                        });
                        return;
                    }

                    this.$http.post("/api/user/save", this.form).then((user) => {
                        this.dialogVisible = false;
                        this.$store.commit("setUser", user);
                        this.$message({
                            message: '设置成功!',
                            type: 'success'
                        });
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },
};
</script>
<style scoped>
.golas>>>input::-webkit-outer-spin-button,
.golas>>>input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>
  